<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 xl6 lg6>
        <v-card elevation="12">
          <v-app-bar flat color="primary">
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>
            <v-toolbar-title class="title white--text pl-0">
              Recover Password
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" icon></v-btn>
          </v-app-bar>
          <v-card-text>
            <v-alert elevation="8" v-show="resetFail" type="error">
              {{ resetFailedMessage }}
            </v-alert>
            <v-alert elevation="8" v-show="resetSuccess" type="success">
              {{ resetSuccessMessage }}
            </v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                prepend-icon="mdi-email"
                :rules="emailRules"
                label="E-mail"
                required
              >
              </v-text-field>
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading || !valid"
                color="primary"
                @click="validate()"
              >
                Submit
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn color="secondary" class="mr-4" @click="$router.push('/')">
                Return to login
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "Login",

  data: () => ({
    loading: false,
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    password: "",
    resetFail: false,
    resetFailedMessage: "",
    resetSuccessMessage: "",
    resetSuccess: false
  }),
  created: function() {
    if (this.$store.state.user) {
      this.$router.push("/select");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.valid) this.submitForgotPassword();
    },
    submitForgotPassword() {
      if (!this.valid) return;

      this.loader = true;
      this.loading = true;

      axios
        .post("/api/forgot", {
          email: this.email,
          password: this.password
        })
        .then(res => {
          // console.log(res);
          if (res.status === 200) {
            this.resetFail = false;
            this.resetFailedMessage = "";
            this.resetSuccess = true;
            this.resetSuccessMessage =
              "An email has been sent to your account with instructions for reseting your password.";
          } else {
            this.resetFail = true;
            this.resetFailedMessage = "Invalid email.";
          }
        })
        .catch(() => {
          // console.log(err);
          this.resetFail = true;
          this.resetFailedMessage = "Invalid email.";
        })
        .finally(() => {
          this.loading = false;
        });
    } // submitForgotPassword
  }
};
</script>
